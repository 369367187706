<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>{{ messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>{{ messagetype.title_jp }}</h2>
      <div class="mascot_wrap">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Front top' }">ホーム</router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'Front board archive', params: { type: 'board' } }"
                >{{ messagetype.title_jp }}一覧</router-link
              >
            </li>
            <li>
              <span>{{ showmessage.title }}</span>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="post_header">
              <div class="post_header_icon">
                <img :src="iconPath" :alt="messagetype.title_jp" />
              </div>
              <div class="post_header_day">
                <span class="post_header_day_time">
                  <img
                    src="@/assets/front_component/images/assets/board01.png"
                    alt="時間"
                  />
                  <time
                    class="en"
                    :datetime="formatDate_time(showmessage.created_at)"
                    >{{ formatDate(showmessage.created_at) }}</time
                  >
                </span>
                <span class="post_header_day_name"
                  >投稿者：{{ from_user.last_name }}
                  {{ from_user.first_name }}</span
                >
              </div>
            </div>
            <article class="post_area">
              <h1>{{ showmessage.title }}</h1>
              <div
                style="min-height: 300px"
                class="reduce-p-tag-margin"
                v-html="showmessage.content"
              ></div>

              <div v-if="images.length > 0" class="post_img">
                <div
                  class="post_img_wrap"
                  v-for="(image, index) in images"
                  :key="image.id"
                >
                  <a class="pop-modal" :href="'#inline-wrap' + index">
                    <div
                      class="post_img_item"
                      v-bind:style="{
                        backgroundImage: 'url(' + image.imgpath + ')',
                      }"
                    >
                      <div class="hover_text">
                        <div class="txt_wrap">
                          <i class="fas fa-search-plus"></i>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div
                    :id="'inline-wrap' + index"
                    class="mfp-hide inline-wrap all_img"
                    data-group="1"
                  >
                    <div class="img_list">
                      <div class="img_wrap"><img :src="image.imgpath" /></div>
                    </div>
                    <button title="Close (Esc)" type="button" class="mfp-close">
                      ×
                    </button>
                  </div>
                </div>
              </div>

              <div class="good_wrap" v-if="showmessage.good_self !== true">
                <div class="good_button" v-on:click="good_post(showmessage.id)">
                  <div class="icon_wrap">
                    <img
                      src="@/assets/front_component/images/assets/good01.png"
                      alt="いいね"
                      class="mouse_out"
                    />
                    <img
                      src="@/assets/front_component/images/assets/good02.png"
                      alt="いいね"
                      class="mouse_over"
                    />
                  </div>
                  <span class="txt">いいね</span>
                  <span class="en good_num">{{ showmessage.good_num }}</span>
                </div>
              </div>

              <div
                class="good_wrap"
                v-else
                v-on:click="good_delete(showmessage.id)"
              >
                <div class="good_button active">
                  <div class="icon_wrap">
                    <img
                      src="@/assets/front_component/images/assets/good01.png"
                      alt="いいね"
                      class="mouse_out"
                    />
                    <img
                      src="@/assets/front_component/images/assets/good02.png"
                      alt="いいね"
                      class="mouse_over"
                    />
                  </div>
                  <span class="txt">いいね</span>
                  <span class="en good_num">{{ showmessage.good_num }}</span>
                </div>
              </div>

              <div v-if="files.length > 0" class="doc_outwrap">
                <h3>関係ファイルダウンロード</h3>
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in files" :key="file.id">
                    <div class="doc_left">
                      <p>{{ file.display_name }}</p>
                    </div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a
                          v-on:click="dlfile(file.storage_file_name)"
                          class="en"
                          target="_blank"
                          :download="file.updaload_name"
                          >DL</a
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article>

            <Form
              enctype="multipart/form-data"
              autocomplete="off"
              @submit="register"
              v-slot="{ errors }"
            >
              <div class="reply_area" v-if="updated_reply_messages.data != 0">
                <div
                  class="reply_wrap"
                  v-for="(message, index) in updated_reply_messages"
                  :key="message.id"
                  :id="'reply' + (index + 1)"
                >
                  <p class="res_head">
                    <span class="res_num en">{{ index + 1 }}</span
                    ><span class="en">.</span>
                    <span
                      class="res_name"
                      v-if="message.title == '削除された返信コメント'"
                      >―</span
                    >
                    <span class="res_name" v-else-if="message.create_user"
                      >{{ message.create_user.last_name
                      }}{{ message.create_user.first_name }}</span
                    >
                    <span class="res_name" v-else></span>
                    <span class="res_day_time">
                      <img
                        src="@/assets/front_component/images/assets/board01.png"
                        alt="時間"
                      />
                      <time
                        class="en"
                        :datetime="formatDate_time(message.created_at)"
                        >{{ formatDate(message.created_at) }}</time
                      >
                    </span>
                  </p>
                  <p v-if="message.reply_com_id" class="p_t10">
                    <a
                      class="reply_com_link"
                      :href="'#reply' + message.reply_com_id"
                      >>> {{ message.reply_com_id }}</a
                    >
                  </p>
                  <div class="post_area">
                    <p v-html="htmlText(message.content)"></p>

                    <div class="post_img">
                      <div
                        class="post_img_wrap"
                        v-for="(image, i) in message.imgpath"
                        :key="image"
                        @click.stop
                      >
                        <a
                          class="pop-modal-comment"
                          :href="'#inline-wrap-comment' + message.id + i"
                        >
                          <div
                            class="post_img_item"
                            v-bind:style="{
                              backgroundImage: 'url(' + image + ')',
                            }"
                          >
                            <div class="hover_text">
                              <div class="txt_wrap">
                                <i class="fas fa-search-plus"></i>
                              </div>
                            </div>
                          </div>
                        </a>
                        <div
                          :id="'inline-wrap-comment' + message.id + i"
                          class="mfp-hide inline-wrap all_img"
                          data-group="1"
                        >
                          <div class="img_list">
                            <div class="img_wrap"><img :src="image" /></div>
                          </div>
                          <button
                            title="Close (Esc)"
                            type="button"
                            class="mfp-close"
                          >
                            ×
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="control_area"
                    v-if="message.title == '削除された返信コメント'"
                  ></div>
                  <div class="control_area" v-else>
                    <a
                      href="#reply_textarea"
                      class="reply_button"
                      v-on:click="reply_com(index + 1)"
                    >
                      <div class="icon_wrap">
                        <img
                          src="@/assets/front_component/images/assets/reply01.png"
                          alt="返信"
                          class="mouse_out"
                        />
                        <img
                          src="@/assets/front_component/images/assets/reply02.png"
                          alt="返信"
                          class="mouse_over"
                        />
                      </div>
                      <span class="txt">返信</span>
                    </a>

                    <div
                      class="good_button"
                      v-if="message.good_self !== true"
                      v-on:click="good_post(message.id)"
                    >
                      <div class="icon_wrap">
                        <img
                          src="@/assets/front_component/images/assets/good03.png"
                          alt="いいね"
                          class="mouse_out"
                        />
                        <img
                          src="@/assets/front_component/images/assets/good02.png"
                          alt="いいね"
                          class="mouse_over"
                        />
                      </div>
                      <span class="txt">いいね</span>
                      <span class="en good_num">{{ message.good_num }}</span>
                    </div>

                    <div
                      class="good_button active"
                      v-else
                      v-on:click="good_delete(message.id)"
                    >
                      <div class="icon_wrap">
                        <img
                          src="@/assets/front_component/images/assets/good03.png"
                          alt="いいね"
                          class="mouse_out"
                        />
                        <img
                          src="@/assets/front_component/images/assets/good02.png"
                          alt="いいね"
                          class="mouse_over"
                        />
                      </div>
                      <span class="txt">いいね</span>
                      <span class="en good_num">{{ message.good_num }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="com_area" id="reply_textarea">
                <div class="com_wrap">
                  <p class="com_name">
                    {{ $User.last_name }} {{ $User.first_name }}
                  </p>
                  <Field
                    name="title"
                    type="hidden"
                    class="form-control"
                    :class="{ 'is-invalid': errors.title }"
                    v-model="create_message.title"
                    :rules="isRequired"
                  />
                  <ErrorMessage class="each_msg" name="title" />
                  <p class="reply_target p_b5">
                    <span>返信先：>> </span
                    ><span class="reply_target_num">1</span
                    ><span class="remove_reply" v-on:click="reply_com_remove()"
                      >×</span
                    >
                  </p>

                  <Field
                    name="content"
                    type="hidden"
                    class="form-control"
                    placeholder="コメントを入力してください。"
                    :class="{ 'is-invalid': errors.content }"
                    v-model="create_message.content"
                    :rules="isRequired"
                  />

                  <div class="comment">
                    <textarea
                      rows="5"
                      placeholder="コメントを入力してください。"
                      v-model="create_message.content"
                    >
                    </textarea>
                    <div class="wrap wid_100per t_l m_b20">
                      <div class="camera_btn" id="post_img_area">
                        <label>
                          <input
                            type="file"
                            name="image[]"
                            class="input-file form-control post_img-file"
                            accept="image/*"
                          />
                          <span>画像を選択</span>
                        </label>
                      </div>
                    </div>
                    <div
                      id="preview"
                      class="wrap wid_100per"
                      style="display: none"
                    ></div>
                  </div>
                  <Field
                    name="parent_id"
                    type="hidden"
                    class="form-control"
                    :class="{ 'is-invalid': errors.title }"
                    v-model="create_message.parent_id"
                    :rules="isRequired"
                  />
                  <div class="t_r">
                    <div class="btn_norm">
                      <button type="submit" class="btn btn-primary">
                        送信
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <div class="t_c m_t100 m_b50">
              <div class="btn_wide blue">
                <a href="javascript:history.back();">もどる</a>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
  <template id="post_img_btn">
    <label>
      <input
        type="file"
        name="image[]"
        class="input-file form-control post_img-file"
        accept="image/*"
      />
      <!-- capture="camera" -->
      <span>画像を選択</span>
    </label>
  </template>
  <template id="remove_btn">
    <span class="remove"><i class="fas fa-times"></i></span>
  </template>
  <template id="item_doc_Template">
    <div class="attached_wrap new_item">
      <div class="attached_name_btn">
        <input
          name="rel_doc_title[]"
          type="text"
          class="fileName form-control"
          required
          placeholder="例：今月のスケジュールについて"
        />
      </div>
      <div class="attached_fire_btn">
        <label>
          <input
            type="file"
            name="rel_doc[]"
            class="input-file form-control"
            accept=".pdf"
          />
          <span class="file_name">添付ファイル</span>
        </label>
      </div>
      <div class="attached_action_btn">
        <div class="add_task_link task_link_btn">
          <i class="fas fa-plus"></i>
        </div>
        <div class="delete_task_link task_link_btn" tabindex="-1">
          <i class="fas fa-minus"></i>
        </div>
      </div>
      <div class="fileDisplayName"></div>
    </div>
  </template>
</template>

<script>
import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/select2/select2.min.js";
import Api from "@/apis/Api";
import message from "@/apis/Message";
import user from "@/apis/User";
import dayjs from "dayjs";
import MessageType from "@/const/MessageType.json";
import { Form, Field, ErrorMessage } from "vee-validate";

import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/jquery.magnific-popup.min.js";
import $ from "jquery";
import event from "@/apis/Event";

export default {
  name: "message",
  data() {
    return {
      reply_messages: [],
      updated_reply_messages: [],

      commentMessageImage: [],
      create_message: {
        title: "掲示板の返信コメント",
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: "board_res",
        content_type: "HTML",
        public: "非公開",
        status: "公開",
        parent_id: this.$route.params.id,
      },
      good_message: {
        title: "掲示板のいいね",
        content: "掲示板のいいね",
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: "board_good",
        content_type: "HTML",
        public: "非公開",
        status: "公開",
        parent_id: "",
      },
      iconPath: "",
      showmessage: {},
      from_user: {},
      files: {},
      images: {},
      MessageType() {
        return MessageType;
      },
      reply_com_id: "",
      loader: false,
      images_file: [],
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      // $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      // $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = "board";
    this.message_id = this.$route.params.id;
    this.showmessageViewPopup(this.message_id);
    this.messagetype = MessageType[this.message_type];
    this.iconPath = require("@/assets/front_component/images/assets/" +
      this.messagetype.imgPath);
    this.getFile();
    this.getImage();
    this.replyList(this.message_id);
  },
  methods: {
    async register() {
      this.images_file = [];

      for (let i = 0; i < $("#post_img_area .check_img").length; i++) {
        let $el = $(".check_img:eq(" + i + ")");
        let $fileData = $el.find(".input-file");

        let fileObject = new FormData();

        fileObject.append("key", "post_images");
        fileObject.append("display_name", "掲示板の投稿画像");
        fileObject.append("uploadfile", $fileData[0].files[0]);
        this.images_file.push(fileObject);
      }

      $("#js-loader").fadeIn();
      await message
        .register(this.create_message)
        .then(
          async (response) => {
            if (this.images_file.length > 0) {
              await this.postMessageImagesfile(response.data.data.id);
            }

            if (this.reply_com_id) {
              await this.postMessageMeta(
                "reply_com",
                this.reply_com_id,
                "返信コメントへの返信",
                response.data.data.id
              );
            }

            // this.$router.push({
            //   name: "Front board show",
            //   params: { type: "board", id: this.message_id },
            // });
            // location.reload(true);
            this.message_type = "board";
            this.message_id = this.$route.params.id;
            await this.showmessageViewPopup(this.message_id);
            this.messagetype = MessageType[this.message_type];
            this.iconPath = require("@/assets/front_component/images/assets/" +
              this.messagetype.imgPath);
            await this.getFile();
            await this.getImage();
            await this.replyList(this.message_id);
            this.create_message.content = "";
            $("#js-loader").fadeOut();
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          $(".post_img_wrap").remove();
          $(".post_img_area").remove();
          $("#post_img_area .check_img").remove();
        });

      $(".post_img_area").remove();
      $(".post_img_area").remove();
      $("#post_img_area .check_img").remove();
    },
    async postMessageMeta(key, value, description, id) {
      var post_json = {
        key: key,
        value: value,
        description: description,
      };
      message
        .register_meta(post_json, id)
        .then(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    reply_com(id) {
      this.reply_com_id = "";
      this.reply_com_id = id;
      $(".reply_target").show();
      $(".reply_target_num").text(this.reply_com_id);
    },
    reply_com_remove() {
      this.reply_com_id = "";
      $(".reply_target").hide();
      $(".reply_target_num").text("");
    },
    good_delete(id) {
      if (this.showmessage.id == id) {
        message
          .listChild(id, "board_good")
          .then(
            (response) => {
              if (response != null) {
                response.data.data.forEach((ob, i) => {
                  if (ob.act_users[0].user_id == this.$User.id) {
                    message
                      .delete(ob.id)
                      .then((response) => {
                        if (response != null) {
                          this.showmessage.good_num =
                            this.showmessage.good_num - 1;
                          this.showmessage.good_self = false;
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {
                        this.loader = false;
                      });
                  }
                });
              }
            },
            (error) => {
              console.log(error);
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        message
          .listChild(id, "board_good")
          .then(
            (response) => {
              if (response != null) {
                response.data.data.forEach((ob, i) => {
                  if (ob.act_users[0].user_id == this.$User.id) {
                    message
                      .delete(ob.id)
                      .then((response) => {
                        if (response != null) {
                          this.reply_messages.forEach((rep_ob, rep_i) => {
                            //いいね数の反映
                            if (id == rep_ob.id) {
                              this.reply_messages[rep_i].good_num =
                                this.reply_messages[rep_i].good_num - 1;
                              this.reply_messages[rep_i].good_self = false;
                            }

                            // Elavarasan
                            message
                              .showfile(
                                this.reply_messages[rep_ob].id,
                                "key",
                                "post_images"
                              )
                              .then(
                                (response) => {
                                  if (response != "") {
                                    let images = response.data.data;

                                    var commentImages = [];

                                    if (images.length > 0) {
                                      images.forEach(async (element) => {
                                        let path = element.storage_file_name;

                                        let fileApiPass =
                                          "/api/v1/file/" + path;

                                        await Api()
                                          .get(fileApiPass, {
                                            responseType: "arraybuffer",
                                          })
                                          .then(
                                            (response) => {
                                              let blob = new Blob(
                                                [response.data],
                                                {
                                                  type: response.headers[
                                                    "content-type"
                                                  ],
                                                }
                                              );
                                              let url =
                                                window.URL || window.webkitURL;
                                              let src =
                                                url.createObjectURL(blob);

                                              commentImages.push(src);
                                              this.reply_messages[
                                                rep_ob
                                              ].imgpath = commentImages;
                                            },
                                            (error) => {
                                              console.log(error);
                                            }
                                          );
                                      });

                                      this.reply_messages[rep_ob].imgpath =
                                        commentImages;
                                    }
                                  }
                                },
                                (error) => {}
                              )
                              .catch(() => {})
                              .finally(() => {});
                          });
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {
                        this.loader = false;
                      });
                  }
                });
              }
            },
            (error) => {
              console.log(error);
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
    good_post(id) {
      this.good_message.parent_id = id;
      message
        .register(this.good_message)
        .then(
          (response) => {
            if (this.showmessage.id == id) {
              this.showmessage.good_num = this.showmessage.good_num + 1;
              this.showmessage.good_self = true;
            } else {
              this.reply_messages.forEach((ob, i) => {
                message
                  .showfile(this.reply_messages[ob].id, "key", "post_images")
                  .then(
                    (response) => {
                      if (response != "") {
                        let images = response.data.data;

                        var commentImages = [];

                        if (images.length > 0) {
                          images.forEach(async (element) => {
                            let path = element.storage_file_name;

                            let fileApiPass = "/api/v1/file/" + path;

                            await Api()
                              .get(fileApiPass, {
                                responseType: "arraybuffer",
                              })
                              .then(
                                (response) => {
                                  let blob = new Blob([response.data], {
                                    type: response.headers["content-type"],
                                  });
                                  let url = window.URL || window.webkitURL;
                                  let src = url.createObjectURL(blob);

                                  commentImages.push(src);
                                  this.reply_messages[ob].imgpath =
                                    commentImages;
                                },
                                (error) => {
                                  console.log(error);
                                }
                              );
                          });

                          this.reply_messages[ob].imgpath = commentImages;
                        }
                      }
                    },
                    (error) => {}
                  )
                  .catch(() => {})
                  .finally(() => {});

                //いいね数の反映
                if (ob.id == id) {
                  this.reply_messages[i].good_num =
                    this.reply_messages[i].good_num + 1;
                  this.reply_messages[i].good_self = true;
                }
              });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    htmlText(msg) {
      return msg.replace(/\r?\n/g, "<br>");
    },
    async goodList(id) {
      this.loader = true;
      message
        .listChild(id, "board_good")
        .then(
          (response) => {
            if (response != null) {
              this.showmessage.good_num = response.data.data.length;
              response.data.data.forEach((ob, i) => {
                if (ob.act_users[0].user_id == this.$User.id) {
                  this.showmessage.good_self = true;
                }
              });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    async reply_goodList(id, i) {
      this.loader = true;
      await message
        .listChild(id, "board_good")
        .then(
          async (response) => {
            if (response != null) {
              this.reply_messages[i].good_num = response.data.data.length;
              await response.data.data.forEach(async (ob) => {
                if (ob.act_users[0].user_id == this.$User.id) {
                  this.reply_messages[i].good_self = true;
                }
              });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    async replyList(id) {
      this.loader = true;
      await message
        .listChild(id, "board_res")
        .then(
          async (response) => {
            if (response != null) {
              this.reply_messages = response.data.data;

              this.reply_messages.forEach(async (ob, i) => {
                // elavarasan
                await message
                  .showfile(this.reply_messages[i].id, "key", "post_images")
                  .then(
                    async (response) => {
                      if (response != "") {
                        let images = response.data.data;

                        var commentImages = [];

                        if (images.length > 0) {
                          await images.forEach(async (element) => {
                            let path = element.storage_file_name;

                            let fileApiPass = "/api/v1/file/" + path;

                            await Api()
                              .get(fileApiPass, {
                                responseType: "arraybuffer",
                              })
                              .then(
                                async (response) => {
                                  let blob = new Blob([response.data], {
                                    type: response.headers["content-type"],
                                  });
                                  let url = window.URL || window.webkitURL;
                                  let src = url.createObjectURL(blob);

                                  commentImages.push(src);
                                  this.reply_messages[i].imgpath =
                                    commentImages;
                                },
                                (error) => {
                                  console.log(error);
                                }
                              );

                            setTimeout(() => {
                              $(function () {
                                $(".pop-modal-comment").magnificPopup({
                                  type: "inline",
                                  preloader: false,
                                  closeOnContentClick: false,
                                  closeBtnInside: true,
                                  removalDelay: 300,
                                  gallery: {
                                    //ギャラリー表示にする
                                    enabled: true,
                                  },
                                });
                              });
                            }, 200);
                          });

                          this.reply_messages[i].imgpath = commentImages;
                        }
                      }
                    },
                    (error) => {}
                  )
                  .catch(() => {})
                  .finally(() => {});

                await user
                  .show(ob.act_users[0].user_id) //投稿者の取得
                  .then(async (response) => {
                    if (response != null) {
                      this.reply_messages[i].create_user = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});

                this.reply_messages[i].good_self = false; //いいねの取得
                this.reply_goodList(ob.id, i);

                await message
                  .getMeta(ob.id) //metaの取得
                  .then(async (response) => {
                    if (response != null) {
                      if (response.data.data[0]) {
                        this.reply_messages[i].reply_com_id =
                          response.data.data[0].value;
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              });

              // console.log(this.reply_messages);
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD HH:mm:ss"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    async showmessageViewPopup(id) {
      // showmessage
      this.loader = true;
      await message
        .show(id)
        .then(
          async (response) => {
            if (response != null) {
              this.showmessage = response.data.data;
              if (this.showmessage.act_users[0]["user_id"] != null) {
                //投稿者の取得
                this.getFromUser(this.showmessage.act_users[0]["user_id"]);
              }
              await this.goodList(id);
              this.showmessage.good_self = false;
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    async getFromUser(user_id) {
      await user
        .show(user_id)
        .then(async (response) => {
          if (response != null) {
            this.from_user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    async getFile() {
      await message
        .showfile(this.$route.params.id, "key", "documents")
        .then(
          async (response) => {
            if (response != "") {
              this.files = response.data.data;
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch(() => {
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    async getImage() {
      await message
        .showfile(this.$route.params.id, "key", "post_images")
        .then(
          async (response) => {
            if (response != "") {
              this.images = response.data.data;
              await this.images.forEach(async (ob, i) => {
                let path = ob.storage_file_name;
                let fileApiPass = "/api/v1/file/" + path;
                Api()
                  .get(fileApiPass, { responseType: "arraybuffer" })
                  .then(
                    async (response) => {
                      let blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                      });
                      let url = window.URL || window.webkitURL;
                      let src = url.createObjectURL(blob);
                      this.images[i].imgpath = src;
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              });

              console.log("errorr happended");
              $(function () {
                $(".pop-modal").magnificPopup({
                  type: "inline",
                  preloader: false,
                  closeOnContentClick: false,
                  closeBtnInside: true,
                  removalDelay: 300,
                  gallery: {
                    //ギャラリー表示にする
                    enabled: true,
                  },
                });
              });
              $("figure.media").each(function () {
                var $y_url = $(this)
                  .children()
                  .attr("url")
                  .replace("https://youtu.be/", "");
                $(this)
                  .children()
                  .replaceWith(
                    "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
                  );
                $(this)
                  .children(".post_youtube")
                  .attr("src", "https://www.youtube.com/embed/" + $y_url);
              });
              // console.log(this.images);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch(() => {
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    async dlfile(path) {
      this.windowReference = window.open();
      //let fileApiPass = '/api/v1/file/adedd8819fecb6f2abd2756a7771803d?attachment';
      let fileApiPass = "/api/v1/file/" + path;
      await Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then(async (response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
          /**
           * 疑似ダウンロード
           */
          //let link= document.createElement('a')
          //link.href= window.URL.createObjectURL(blob)
          //link.download= response.headers['content-filename']
          // // 以下コメントアウトで実行
          // link.click()
        });
    },
    async postMessageImagesfile(createdId) {
      for (var i = 0; i < this.images_file.length; i++) {
        await message
          .register_file(this.images_file[i], createdId)
          .then(
            (response) => {
              if (i == this.images_file.length - 1) {
                if (response != "") {
                  console.log(response);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .catch(() => {})
          .finally(() => {});
      }
    },

    async getCommentImages() {
      await message
        .showfile(this.$route.params.id, "key", "post_images")
        .then(
          async (response) => {
            if (response != "") {
              this.images = response.data.data;
              this.images.forEach((ob, i) => {
                let path = ob.storage_file_name;
                let fileApiPass = "/api/v1/file/" + path;
                // console.log(fileApiPass);
                Api()
                  .get(fileApiPass, { responseType: "arraybuffer" })
                  .then(
                    async (response) => {
                      let blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                      });
                      let url = window.URL || window.webkitURL;
                      let src = url.createObjectURL(blob);
                      this.images[i].imgpath = src;
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              });

              $(function () {
                $(".pop-modal").magnificPopup({
                  type: "inline",
                  preloader: false,
                  closeOnContentClick: false,
                  closeBtnInside: true,
                  removalDelay: 300,
                  gallery: {
                    //ギャラリー表示にする
                    enabled: true,
                  },
                });
              });
              $("figure.media").each(function () {
                var $y_url = $(this)
                  .children()
                  .attr("url")
                  .replace("https://youtu.be/", "");
                $(this)
                  .children()
                  .replaceWith(
                    "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
                  );
                $(this)
                  .children(".post_youtube")
                  .attr("src", "https://www.youtube.com/embed/" + $y_url);
              });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch(() => {
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
  },
  mounted() {
    document.body.className = "page_board-single";
    this.$nextTick(function () {
      $(document).ready(function () {
        var not = 0;
        $(document).on("change", ".post_img-file", function (event) {
          not++;
          var num = "num" + not;
          var eventDOM = event.target;
          eventDOM.parentNode.classList.add(num);
          eventDOM.parentNode.classList.add("check_img");
          eventDOM.parentNode.style.display = "none";

          if (!this.files.length) {
            return;
          }

          var $files = $(this).prop("files");
          var len = $files.length;
          for (var i = 0; i < len; i++) {
            var file = $files[i];
            var fr = new FileReader();

            fr.onload = function (e) {
              let img_div = document.createElement("div");
              img_div.classList.add("post_img_wrap");
              img_div.id = num;
              var src = e.target.result;
              let img = document.createElement("img");
              var template = document.getElementById("remove_btn");
              var clone = template.content.cloneNode(true);
              img.src = src;
              img_div.append(img);
              img_div.appendChild(clone);
              $("#preview").append(img_div);
            };

            fr.readAsDataURL(file);
          }

          $("#preview").css("display", "block");

          var $postarea = document.getElementById("post_img_area");
          var postImgBtn = document.getElementById("post_img_btn");
          var htmlString = postImgBtn.content.cloneNode(true);
          $postarea.append(htmlString);
        });

        $(document).on("click", ".remove", function (e) {
          var $el = $(e.currentTarget);
          var $row = $el.closest("div");
          var $target_id = $row.attr("id");
          $row.remove();
          $("#post_img_area label").each(function () {
            if ($(this).hasClass($target_id)) {
              $(this).remove();
            }
          });
          return false;
        });
      });

      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });

      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          // var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        // $(window).scrollTop(0);
      });
      $(function () {
        var ua = navigator.userAgent;
        $(document).on(
          "click",
          'a[href^="#"]:not(".pop-modal"):not(".news-modal")',
          function (event) {
            event.preventDefault();

            var speed = 550;
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? "html" : href);
            var position;

            if (
              ua.indexOf("iPhone") > 0 ||
              (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
            ) {
              position = target.offset().top - 210; //ヘッダの高さ分位置をずらす
            } else {
              position = target.offset().top - 110; //ヘッダの高さ分位置をずらす
            }

            $("html, body").animate({ scrollTop: position }, speed, "swing");
            return false;
          }
        );
      });

      $(function () {
        var ua = navigator.userAgent;
        $(document).on(
          "click",
          'a[href^="#"]:not(".pop-modal-comment"):not(".news-modal")',
          function () {
            var speed = 550;
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? "html" : href);
            if (
              ua.indexOf("iPhone") > 0 ||
              (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
            ) {
              var position = target.offset().top - 210; //ヘッダの高さ分位置をずらす
            } else {
              position = target.offset().top - 110; //ヘッダの高さ分位置をずらす
            }

            $("html, body").animate({ scrollTop: position }, speed, "swing");
            return false;
          }
        );
      });

      //Jquery
      // youtubeがタグの構成上表示出来ないので以下でタグを変換している
      $(function () {
        $("figure.media").each(function () {
          var $y_url = $(this)
            .children()
            .attr("url")
            .replace("https://youtu.be/", "");
          $y_url = $(this)
            .children()
            .attr("url")
            .replace("https://www.youtube.com/watch?v=", "");
          $(this)
            .children()
            .replaceWith(
              "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
            );
          // console.log($y_url);
          $(this)
            .children(".post_youtube")
            .attr("src", "https://www.youtube.com/embed/" + $y_url);
        });
      });
    });
  },
  updated() {},
  computed: {
    // commentMessageImage() {
    //   return this.reply_messages;
    // },
  },
  watch: {
    reply_messages(val) {
      this.updated_reply_messages = val;
    },
  },
};
</script>

<style scoped>
</style>



